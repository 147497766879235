/* eslint-disable jsx-a11y/click-events-have-key-events,
jsx-a11y/no-static-element-interactions,
react/no-unused-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-i18nify';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import { UncontrolledTooltip } from 'reactstrap';
import * as Sentry from '@sentry/browser';
import { getBase64, checkDimension } from '../../helper/file';

const MAX_FILE_SIZE = 1024 * 1024 * 2; // 2 MB
const MAX_IMAGES_TOTAL_SIZE = 1024 * 1024 * 10; // 10 MB

class Images extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      pending: false,
    };
  }

  upload(e) {
    this.setState({ pending: true, error: '' });

    const { addImage } = this.props;
    const uploadedFile = e.target.files[0];
    const { size } = uploadedFile;
    if (size > MAX_FILE_SIZE) {
      this.setState({ pending: false, error: I18n.t('FILE_TOO_LARGE') });
      return;
    }

    checkDimension(uploadedFile)
      .then(() => getBase64(uploadedFile))
      .then((file) => {
        this.setState({ pending: false, error: '' });
        addImage(file);
      })
      .catch((err) => {
        this.setState({ pending: false, error: err.message });
        Sentry.captureException(err);
      });
  }

  deleteImage(id) {
    const { removeImage } = this.props;
    removeImage(id);
  }

  // Uncomment scale and angle params to enable duplication with scale and angle.
  cloneImage(base64Url/* , scale, angle */) {
    const { addImage } = this.props;
    addImage(base64Url/* , scale, angle */);
  }

  render() {
    const { error, pending } = this.state;
    const {
      images = [],
      onImageClick,
      onImagePin,
      onImageZoom,
      onImageRotate,
      selectedImageId,
      tooltips,
    } = this.props;
    const currentImagesBytesSize = images.reduce((acc, { url = '' } = {}) => acc + url.length, 0);
    const maxSizeExceeded = currentImagesBytesSize >= MAX_IMAGES_TOTAL_SIZE;

    return (
      <div>
        <h4>
          <Translate value="SELECT_IMAGE" />
        </h4>
        <hr />

        <div className="row box">
          <div className="col-12">
            {
              pending ? <Spinner name="line-scale" fadeIn="none" /> : (
                !maxSizeExceeded && (
                  <div>
                    <label htmlFor="file" className="inputfile-label" id="upload-image">
                      <input
                        type="file"
                        id="file"
                        onChange={e => this.upload(e)}
                        className="inputfile"
                        accept="image/*"
                      />
                      <span>
                        <Translate value="CHOOSE_FILE" />
                      </span>
                    </label>
                    {
                      tooltips && (
                        <UncontrolledTooltip placement="bottom" target="upload-image">
                          <Translate value="TOOLTIPS.UPLOAD_IMAGE" />
                        </UncontrolledTooltip>
                      )
                    }
                  </div>
                )
              )
            }
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <span className="error">{error}</span>
          </div>
        </div>

        {
          maxSizeExceeded && (
            <div className="row">
              <div className="col-12">
                <Translate className="error" value="TOO_MANY_FILES" dangerousHTML />
              </div>
            </div>
          )
        }

        <div className="row box">
          {
            images.map(({
              angle,
              id,
              pinned,
              scale,
              url,
            }, index) => (
              <div className="col-4 p-t-10" key={id}>
                <div
                  className={`text-center p-10 image-border${id === selectedImageId ? '-selected' : ''}`}
                  onClick={() => onImageClick(id)}
                >
                  <div id={`image-${index}`} className="loaded-image" style={{ backgroundImage: `url(${url})` }} />
                  {
                    id && [
                      <i
                        id={`delete-image-${index}`}
                        key="delete"
                        className="fas fa-trash image-action"
                        onClick={() => this.deleteImage(id)}
                      />,
                      !maxSizeExceeded && (
                        <i
                          id={`clone-image-${index}`}
                          key="clone"
                          className="fas fa-clone image-action"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.cloneImage(url, scale, angle);
                          }}
                        />
                      ),
                      <i
                        id={`lock-image-${index}`}
                        key="lock"
                        className={`fas fa-lock${pinned ? '' : '-open'} image-action`}
                        onClick={() => onImagePin(id, !pinned)}
                      />,
                    ]
                  }
                  <br />
                  {
                    id && [
                      <i
                        id={`zoom-in-image-${index}`}
                        key="zoom-in"
                        className="fas fa-search-plus image-action"
                        onMouseDown={() => onImageZoom(id, scale, true)}
                        onMouseUp={() => onImageZoom(id)}
                        onMouseLeave={() => onImageZoom()}
                      />,
                      <i
                        id={`zoom-out-image-${index}`}
                        key="zoom-out"
                        className="fas fa-search-minus image-action"
                        onMouseDown={() => onImageZoom(id, scale)}
                        onMouseUp={() => onImageZoom(id)}
                        onMouseLeave={() => onImageZoom()}
                      />,
                      <i
                        id={`rotate-clockwise-image-${index}`}
                        key="rotate-clockwise"
                        className="fas fa-undo image-action vert-spec"
                        onMouseDown={() => onImageRotate(id, angle, true)}
                        onMouseUp={() => onImageRotate(id)}
                        onMouseLeave={() => onImageRotate()}
                      />,
                      <i
                        id={`rotate-counterclockwise-image-${index}`}
                        key="rotate-counterclockwise"
                        className="fas fa-undo image-action"
                        onMouseDown={() => onImageRotate(id, angle)}
                        onMouseUp={() => onImageRotate(id)}
                        onMouseLeave={() => onImageRotate()}
                      />,
                    ]
                  }
                </div>
                {
                  tooltips && [
                    <UncontrolledTooltip key={0} placement="bottom" target={`delete-image-${index}`}>
                      <Translate value="TOOLTIPS.DELETE_IMAGE" />
                    </UncontrolledTooltip>,
                    <UncontrolledTooltip key={1} placement="bottom" target={`clone-image-${index}`}>
                      <Translate value="TOOLTIPS.CLONE_IMAGE" />
                    </UncontrolledTooltip>,
                    <UncontrolledTooltip key={2} placement="bottom" target={`image-${index}`}>
                      <Translate value="TOOLTIPS.SELECT_IMAGE" />
                    </UncontrolledTooltip>,
                    <UncontrolledTooltip key={3} placement="bottom" target={`lock-image-${index}`}>
                      <Translate value="TOOLTIPS.LOCK_IMAGE" />
                    </UncontrolledTooltip>,
                    <UncontrolledTooltip key={4} placement="bottom" target={`zoom-in-image-${index}`}>
                      <Translate value="TOOLTIPS.ZOOM_IN_IMAGE" />
                    </UncontrolledTooltip>,
                    <UncontrolledTooltip key={5} placement="bottom" target={`zoom-out-image-${index}`}>
                      <Translate value="TOOLTIPS.ZOOM_OUT_IMAGE" />
                    </UncontrolledTooltip>,
                    <UncontrolledTooltip key={6} placement="bottom" target={`rotate-clockwise-image-${index}`}>
                      <Translate value="TOOLTIPS.ROTATE_CLOCKWISE_IMAGE" />
                    </UncontrolledTooltip>,
                    <UncontrolledTooltip key={7} placement="bottom" target={`rotate-counterclockwise-image-${index}`}>
                      <Translate value="TOOLTIPS.ROTATE_COUNTERCLOCKWISE_IMAGE" />
                    </UncontrolledTooltip>,
                  ]
                }
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

Images.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  selectedImageId: PropTypes.string.isRequired,
  addImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
  onImagePin: PropTypes.func.isRequired,
  onImageZoom: PropTypes.func.isRequired,
  onImageRotate: PropTypes.func.isRequired,
  tooltips: PropTypes.bool.isRequired,
};

export default connect()(Images);
