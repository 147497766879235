/** @module TShirtConfigurator */

import * as THREE from 'three';
import {BUTTONS, MODES} from './constants';
import normalizeAngle from './normalizeAngle';
import {mapTouchEvents, unmapTouchEvents} from './utils/mapTouchEvents';
import TShirtViewer from './TShirtViewer';

/**
 * The TShirtConfigurator class.
 */
export default class TShirtConfigurator extends TShirtViewer {
    /**
     * Creates a new instance of TShirtConfigurator.
     * @param {HTMLElement} element The HTML element that will contain the TShirt configurator.
     * @param {Object} config Model config.
     */
    constructor(element, config) {
        super(element, config);

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);

        this.element.addEventListener('mousedown', this.handleMouseDown);
        this.element.addEventListener('mousemove', this.handleMouseMove);
        this.element.addEventListener('mouseup', this.handleMouseUp);
        mapTouchEvents(this.element);

        setImmediate(() => {
            this.setMode(MODES.NORMAL);
        });
    }

    /**
     * Disposes the current renderer, this object is no longer usable.
     */
    dispose() {
        unmapTouchEvents(this.element);
        this.element.removeEventListener('mousedown', this.handleMouseDown);
        this.element.removeEventListener('mousemove', this.handleMouseMove);
        this.element.removeEventListener('mouseup', this.handleMouseUp);
        super.dispose();
    }

    /**
     * Load a new model.
     * @param {ModelConfiguration} modelOptions The model configuration.
     * @return {Promise}
     */
    async loadModelAsync(modelOptions) {
        await super.loadModelAsync(modelOptions);
        this.setMode(MODES.NORMAL);
        setImmediate(() => {
            this.emit('selectionChanged', null);
            this.emitUpdatedItems();
        });
    }

    /**
     * Sets the color of the specified part.
     * @param {string} key The part key.
     * @param {Color} color The part color.
     */
    setPartColor(key, color) {
        this.model.setPartColor(key, color);
        this.render();
    }

    /**
     * Sets the specified accessory.
     * @param {string} key The accessory key.
     * @param {number} index The index of the selected accessory.
     */
    setAccessory(key, index) {
        this.model.setAccessory(key, index);
        this.render();
    }

    /**
     * Sets the specified optional.
     * @param {string} key The optional key.
     * @param {boolean} value Toggle optional.
     */
    setOptional(key, value) {
        this.model.setOptional(key, value);
        this.render();
    }

    /**
     * Creates a new text item.
     * @param {string} text The text.
     * @param {Color} color The text color.
     * @param {string} font The text font.
     * @param {boolean} bold If the text should be bold.
     * @param {boolean} italic If the text should be in italics.
     */
    addText(text, color, font, bold, italic) {
        this.setMode(MODES.PICK_TEXT_POSITION, {text, color, font, bold, italic});
    }

    /**
     * Creates a new image item.
     * @param {string} url The URL of the image.
     * @param {number} scale The scale of the image.
     * @param {number} angle The angle of the image.
     */
    addImage(url, scale, angle) {
        this.setMode(MODES.PICK_IMAGE_POSITION, { url, scale, angle });
    }

    /**
     * Updates an existing item.
     * @param {string} id The id of the item.
     * @param {object} options The new options.
     */
    updateItem(id, options) {
        this.model.updateItem(id, options);
        this.render();
        this.emitUpdatedItems();
    }

    /**
     * Removes an existing item.
     * @param {string} id The id of the item to remove.
     */
    removeItem(id) {
        this.model.removeItem(id);
        this.render();
        this.emitUpdatedItems();
    }

    /**
     * Selects an existing item.
     * @param {string} id The id of the item to select.
     */
    selectItem(id) {
        const oldSelection = this._selection;
        const object = this.model.getSelectableObjectFromId(id);
        if (this._selection) {
            delete this._selection.item.selected;
            this._selection.group.draw();
            this.render();
        }
        if (object) {
            this._selection = object;
            this._selection.item.selected = true;
            this._selection.group.draw();
            this.render();
            this.setMode(MODES.NORMAL);
        } else {
            delete this._selection;
        }

        if (oldSelection !== this._selection) {
            this.emit('selectionChanged', this._selection && this._selection.item && this._selection.item.id || null);
        }
    }

    /**
     * @private
     * @param {string} mode The specified editor mode.
     * @param {object?} payload Mode payload data.
     */
    setMode(mode, payload) {
        if (mode !== this._mode) {
            switch (mode) {
                case MODES.NORMAL:
                    this.controls.enabled = true;
                    this.controls.update();
                    break;
                case MODES.MOVE:
                case MODES.PICK_IMAGE_POSITION:
                case MODES.PICK_TEXT_POSITION:
                case MODES.RESIZE:
                case MODES.ROTATE:
                    this.controls.enabled = false;
                    break;
                default:
                    throw new Error(`Invalid mode "${mode}" specified.`);
            }
            this._mode = mode;
            this._modePayload = payload;
            this.emit('modeChanged', mode);
        }
    }

    /**
     * @private
     * @param {MouseEvent} evt Event object.
     */
    async handleMouseDown(evt) {
        switch (this._mode) {
            case MODES.NORMAL:
                if (evt.button === 0) {
                    this.updateSelection(this.getMousePosition(evt));
                }
                break;
            case MODES.PICK_IMAGE_POSITION:
            case MODES.PICK_TEXT_POSITION:
                if (evt.button === 0) {
                    await this.handleClickPickPosition(this.getMousePosition(evt));
                    this.updateSelection(this.getMousePosition(evt));
                }
                break;
        }
    }

    /**
     * @private
     * @param {MouseEvent} evt Event object.
     */
    handleMouseMove(evt) {
        switch (this._mode) {
            case MODES.MOVE:
                this.doMove(this.getMousePosition(evt));
                break;
            case MODES.RESIZE:
                this.doResize(this.getMousePosition(evt));
                break;
            case MODES.ROTATE:
                this.doRotate(this.getMousePosition(evt));
                break;
        }
    }

    /**
     * @private
     * @param {MouseEvent} evt Event object.
     */
    handleMouseUp(evt) {
        switch (this._mode) {
            case MODES.MOVE:
            case MODES.RESIZE:
            case MODES.ROTATE:
                if (evt.button === 0) {
                    this.setMode(MODES.NORMAL);
                    this.emitUpdatedItems();
                }
                break;
        }
    }

    /**
     * Normalizes mouse position.
     * @private
     * @param {MouseEvent} evt Event object.
     * @return {THREE.Vector2}
     */
    getMousePosition(evt) {
        const rect = this.element.getBoundingClientRect();
        const x = (evt.clientX - rect.left) / rect.width;
        const y = (evt.clientY - rect.top) / rect.height;
        return new THREE.Vector2(x, y);
    }

    /**
     * @private
     * @param {THREE.Vector2} position Position in normalized screen coordinates.
     */
    async handleClickPickPosition(position) {
        const intersect = this.model.getSingleIntersect(position, this.camera);
        if (intersect) {
            switch (this._mode) {
                case MODES.PICK_IMAGE_POSITION:
                    await this.model.addImageAsync(this._modePayload, intersect.groupIndex, intersect.point, intersect.uv);
                    break;
                case MODES.PICK_TEXT_POSITION:
                    await this.model.addTextAsync(this._modePayload, intersect.groupIndex, intersect.point, intersect.uv);
                    break;
                default:
                    throw new Error(`Invalid mode "${this._mode}" for handleClickPickPosition.`);
            }
            this.render();
            this.setMode(MODES.NORMAL);
            this.emitUpdatedItems();
        }
    }

    /**
     * @private
     * @param {THREE.Vector2} position Position in normalized screen coordinates.
     */
    updateSelection(position) {
        const oldSelection = this._selection;
        const object = this.model.getSelectableObjectFromPosition(position, this.camera);
        if (this._selection) {
            delete this._selection.item.selected;
            this._selection.group.draw();
            this.render();
        }
        if (object) {
            this._selection = object;
            this._selection.item.selected = true;
            this._selection.group.draw();
            this.render();
            if (object.button) {
                this.handleItemButton(object.button, position);
            } else if (!object.item.pinned) {
                this.setMode(MODES.MOVE, {position});
            }
        } else {
            delete this._selection;
        }

        if (oldSelection !== this._selection) {
            this.emit('selectionChanged', this._selection && this._selection.item && this._selection.item.id || null);
        }
    }

    /**
     * @private
     * @param {THREE.Vector2} position Position in normalized screen coordinates.
     */
    doMove(position) {
        const intersect = this.model.getSingleIntersect(position, this.camera);
        if (intersect && intersect.group.drawable) {
            if (intersect.group !== this._selection.group) {
                this._selection.group.removeItem(this._selection.item);
                this._selection.group = intersect.group;
                this._selection.group.items.push(this._selection.item);
            }
            const uv = this.model.snap(intersect.uv, this._selection.group.snaplines);
            this._selection.item.moveTo(intersect.point, uv);
            this._selection.group.draw();
            this.render();
        }
    }

    /**
     * @private
     * @param {THREE.Vector2} position Position in normalized screen coordinates.
     */
    doResize(position) {
        const initialVector = new THREE.Vector2();
        initialVector.subVectors(this._modePayload.position, this._modePayload.center);
        const currentVector = new THREE.Vector2();
        currentVector.subVectors(position, this._modePayload.center);

        const initialDistance = initialVector.length();
        const currentDistance = currentVector.length();
        const newScale = currentDistance / initialDistance * this._modePayload.scale;

        this._selection.item.scale = Math.abs(newScale);
        this._selection.group.draw();
        this.render();
    }

    /**
     * @private
     * @param {THREE.Vector2} position Position in normalized screen coordinates.
     */
    doRotate(position) {
        const initialVector = new THREE.Vector2();
        initialVector.subVectors(this._modePayload.position, this._modePayload.center);
        const currentVector = new THREE.Vector2();
        currentVector.subVectors(position, this._modePayload.center);

        const initialAngle = initialVector.angle();
        const currentAngle = currentVector.angle();

        const delta = currentAngle - initialAngle;
        this._selection.item.angle = normalizeAngle(this._modePayload.angle + delta);
        this._selection.group.draw();
        this.render();
    }

    /**
     * @private
     * @param {string} button
     * @param {THREE.Vector2} position Position in normalized screen coordinates.
     */
    handleItemButton(button, position) {
        switch (button) {
            case BUTTONS.RESIZE:
                this.setMode(MODES.RESIZE, {center: this.project(this._selection.item.point), position, scale: this._selection.item.scale});
                break;
            case BUTTONS.ROTATE:
                this.setMode(MODES.ROTATE, {center: this.project(this._selection.item.point), position, angle: this._selection.item.angle});
                break;
            case BUTTONS.TRASH:
                this._selection.group.removeItem(this._selection.item);
                delete this._selection;
                this.render();
                this.emit('selectionChanged', null);
                this.emitUpdatedItems();
                break;
            default:
                throw new Error(`Unknown button type "${button}".`);
        }
    }

    /**
     * @private
     * @param {THREE.Vector3} point Input point in world coordinates
     * @return {THREE.Vector2} Point in screen coordinates.
     */
    project(point) {
        const result = point.clone();
        result.project(this.camera);
        result.x = result.x / 2 + .5;
        result.y = -result.y / 2 + .5;
        return new THREE.Vector2(result.x, result.y);
    }

    /**
     * @private
     */
    emitUpdatedItems() {
        this.emit('itemsUpdated', this.model.getItems().map(
            (item) => Object.assign({}, item.toJson(), {effectiveSize: item.effectiveSize})
        ));
    }
}
