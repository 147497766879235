/* eslint-disable object-curly-newline */
import React, { Component } from 'react';
import { Translate, I18n } from 'react-i18nify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { assign, pick, set } from 'lodash';
import Loadable from 'react-loading-overlay';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { Alert, TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import { withAlert } from 'react-alert';
import { saveAs } from 'file-saver';
import * as Sentry from '@sentry/browser';
import NavConfiguration from './NavConfiguration';
import Variant from './Variant';
import Color from './Colors';
import Text from './Text';
import Images from './Images';
import TShirtConfigurator from '../../configurator/TShirtConfigurator';
import { get, getBaseVariant } from '../../api/models';
import { setVariant } from '../../actions/variant';
import { color2Number, isLight } from '../../helper/colors';
import { setConfig } from '../../actions/config';
import { clear } from '../../actions/actions';
import { setModel } from '../../actions/model';
import { setModelName } from '../../actions/modelName';
import Accessory from './Accessory';
import BannerMode from './BannerMode';
import { MODES, OBJECT_TYPES } from '../../configurator/constants';
import { IS_FIREFOX, IS_MOBILE } from '../../constants';

class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      variants: [],
      loading: false,
      mode: '',
      images: [],
      texts: [],
      showConfiguratorAlert: true,
      showUnsupportedMobileAlert: IS_MOBILE,
      selectedImageId: '',
    };
  }

  componentDidMount() {
    const {
      model: storeModel,
      dispatchClear,
      dispatchSetConfig,
      dispatchSetModel,
      dispatchSetModelName,
      dispatchSetVariant,
      history,
      location: { search = '' },
      alert,
      variant,
    } = this.props;
    const {
      model: queryModel,
      name: queryModelName,
    } = queryString.parse(search);
    if (!queryModel) {
      history.push('/404');
      return;
    }
    if (queryModel !== storeModel) {
      dispatchClear();
      dispatchSetModel(queryModel);
    }
    if (queryModelName) {
      dispatchSetModelName(queryModelName);
    }
    const model = queryModel;

    setTimeout(() => this.setState({ showConfiguratorAlert: false }), 10000);
    const initConfigurator = (config) => {
      const configuratorElement = document.getElementById('configurator');
      this.configurator = new TShirtConfigurator(configuratorElement, config);
      this.configurator.on('modelLoaded', () => {
        this.setState({ loading: false });
        dispatchSetConfig(this.getDispatchData());
      });
      this.configurator.on('modeChanged', (newMode) => {
        const { mode } = this.state;
        if (newMode === MODES.NORMAL
          && (mode === MODES.MOVE || mode === MODES.RESIZE || mode === MODES.ROTATE)) {
          dispatchSetConfig(this.getDispatchData());
        }
        this.setState({ mode: newMode });
      });
      this.configurator.on('itemsUpdated', (items = []) => {
        const texts = items.filter(({ type }) => type === OBJECT_TYPES.TEXT);
        const images = items.filter(({ type }) => type === OBJECT_TYPES.IMAGE);
        this.setState({ texts, images });
        dispatchSetConfig(this.getDispatchData());
      });
      this.configurator.on('selectionChanged', (selectedImageId) => {
        this.setState({ selectedImageId });
      });

      window.addEventListener('resize', () => {
        if (!this.configurator) return;
        this.configurator.resize();
      });
    };

    get(model)
      .then((result) => {
        const { config = {} } = result;
        initConfigurator(config);
        const { variant } = this.props;
        if (!variant) {
          return getBaseVariant(model)
            .then(baseVariant => dispatchSetVariant(baseVariant))
            .then(() => result);
        }
        return result;
      })
      .then((result) => {
        const { config: configInStore } = this.props;
        const { config = {}, variants = [] } = result;
        const configToSave = isEmpty(configInStore) ? config : configInStore;
        this.setState({ variants, loading: true });
        const { accessories = [], groups = [], parts = [] } = configToSave;
        if (!variant) {
          configToSave.groupsCopy = groups;
          configToSave.groups = [];
        }
        this.configurator.loadModelAsync(configToSave)
          .then(() => this.setInitialAccessoriesColors(accessories, parts));
      })
      // eslint-disable-next-line consistent-return
      .catch((err) => {
        Sentry.captureException(err);
        if (err === 'Invalid model') {
          return history.push('/404');
        }
        alert.error(I18n.t('ERROR'));
      });
  }

  componentDidUpdate(prevProps) {
    const { tooltips } = this.props;
    if (!tooltips || tooltips === prevProps.tooltips) return;
    this.showConfiguratorAlert();
  }

  componentWillUnmount() {
    const { configurator } = this;
    if (!configurator) return;
    configurator.removeAllListeners();
    configurator.dispose();
    delete this.configurator;
  }

  onChangeVariant(id) {
    const {
      dispatchClear,
      dispatchSetModel,
      dispatchSetVariant,
      config,
      model,
    } = this.props;
    const { variants = [] } = this.state;
    const {
      parts: partsInConfig = [],
      accessories: accessoriesInConfig,
      groupsCopy,
    } = config;

    dispatchClear();
    dispatchSetModel(model);
    dispatchSetVariant(id);

    const { overlays, parts = [], accessories = [] } = variants.find(v => v.id === id) || {};

    /* Maintain selected color */
    const coloredParts = parts.map((part) => {
      const p = partsInConfig.find(({ key }) => part && key === part.key);
      if (!p) return part;
      return assign(part, pick(p, ['color', 'pantone', 'pantoneName']));
    });

    /* Maintain selected accessories */
    const selectedAccessories = accessories.map((accessory) => {
      const a = accessoriesInConfig.find(({ key }) => accessory && key === accessory.key);
      if (!a) return accessory;
      const { current = 0 } = a;
      const { url = [] } = accessory;
      return assign(accessory, { current: url[current] ? current : 0 });
    });

    this.setState({ loading: true });
    const modelOptions = {
      ...config,
      overlays,
      parts: coloredParts,
      accessories: selectedAccessories,
    };
    if (groupsCopy) {
      modelOptions.groups = groupsCopy;
      delete modelOptions.groupsCopy;
    }
    this.configurator.loadModelAsync(modelOptions)
      .then(() => this.setInitialAccessoriesColors(accessories, coloredParts));
  }

  onChangeColor(color) {
    const {
      key,
      hex,
      pantone,
      pantoneName,
    } = color;
    const { config, dispatchSetConfig } = this.props;
    const { accessories = [] } = config;

    const newConfig = assign({}, config);
    const part = newConfig.parts.find(({ key: k }) => k === key);
    if (part) {
      part.color = parseInt(hex, 16);
      part.pantone = pantone;
      part.pantoneName = pantoneName;
    }

    dispatchSetConfig(newConfig);
    this.configurator.setPartColor(key, hex);

    /* Change logo color */
    this.changeAccessoryColor(hex, accessories, part.partAccessories);

    /* Set color for same part of all variants */
    const { variants } = this.state;
    variants.forEach((variant) => {
      const i = variant.parts.findIndex(p => p.key === key);
      if (i >= 0) {
        set(variant, `parts[${i}].color`, part.color);
        set(variant, `parts[${i}].pantone`, part.pantone);
        set(variant, `parts[${i}].pantoneName`, part.pantoneName);
      }
    });
  }

  onAddText({
    id, text, hex, font, bold, italic, pantone, pantoneName,
  }) {
    if (!id) {
      this.configurator.addText(text, color2Number(hex), font, bold, italic);
      return;
    }
    const opts = {
      color: color2Number(hex),
      text,
      font,
      bold,
      italic,
      pantone,
      pantoneName,
    };
    this.configurator.updateItem(id, opts);
  }

  onAddImage(url, scale, angle) {
    this.configurator.addImage(url, scale, angle);
  }

  onImageClick(id) {
    this.configurator.selectItem(id);
  }

  onImagePin(id, pinned) {
    this.configurator.updateItem(id, { pinned });
  }

  onItemZoom(id, intialScale = 1, zoomIn) {
    if (!id || this.zoomInterval) {
      clearInterval(this.zoomInterval);
      delete this.zoomInterval;
      delete this.scale;
      return;
    }
    this.scale = intialScale;
    this.zoomInterval = setInterval(() => {
      const zoomStep = 0.01;
      this.scale += zoomIn ? zoomStep : -zoomStep;
      this.configurator.updateItem(id, { scale: this.scale });
    }, 50);
  }

  onItemRotate(id, initialAngle = 0, clockwise) {
    if (!id || this.rotateInterval) {
      clearInterval(this.rotateInterval);
      delete this.rotateInterval;
      delete this.angle;
      return;
    }
    this.angle = initialAngle;
    this.rotateInterval = setInterval(() => {
      const angleStep = 0.01;
      this.angle += clockwise ? angleStep : -angleStep;
      this.configurator.updateItem(id, { angle: this.angle });
    }, 50);
  }

  onItemRemove(id) {
    this.configurator.removeItem(id);
  }

  onChangeAccessory(key, index) {
    this.configurator.setAccessory(key, index);
    const { dispatchSetConfig } = this.props;
    dispatchSetConfig(this.getDispatchData());
  }

  onChangeOptional(key, index) {
    this.configurator.setOptional(key, index);
    const { dispatchSetConfig } = this.props;
    dispatchSetConfig(this.getDispatchData());
  }

  setInitialAccessoriesColors(accessories = [], parts = []) {
    parts.forEach(({ color, partAccessories = [] }) => {
      const hex = `0x${color.toString(16)}`;
      this.changeAccessoryColor(hex, accessories, partAccessories);
    });
  }

  getDispatchData() {
    const { configurator } = this;
    if (!configurator) return null;
    const exportData = configurator.exportModel();
    const { config: currentConfig = {} } = this.props;
    const { parts } = currentConfig;
    return isEmpty(currentConfig) ? exportData : { ...exportData, parts };
  }

  changeAccessoryColor(hex, accessories = [], partAccessories = []) {
    partAccessories.forEach((partAccessoryKey) => {
      const { url = [] } = accessories
        .find(({ key: accessoryKey }) => accessoryKey === partAccessoryKey) || {};
      if (url.length < 2) return;
      const optionIndex = isLight(hex) ? 1 : 0;
      this.onChangeAccessory(partAccessoryKey, optionIndex);
    });
  }

  showConfiguratorAlert() {
    this.setState({ showConfiguratorAlert: true });
  }

  reloadConfig() {
    this.setState({ loading: true });
    setTimeout(() => {
      const { config } = this.props;
      this.configurator.loadModelAsync(config);
    }, 500);
  }

  exportPng() {
    // const { model = '' } = this.props;
    // let { variant = '' } = this.props;
    const { variant = '' } = this.props;
    // if (variant === 'base') variant = '';
    // else if (variant === 'first') variant = '_variant';
    // else if (variant === 'second') variant = '_second_variant';
    const base64 = this.configurator.exportPng();
    saveAs(base64, `${variant.toUpperCase()}.png`);
  }

  render() {
    const {
      config,
      tooltips,
      variant,
    } = this.props;
    const {
      parts = [],
      accessories = [],
      optionals = [],
      hasNamedAccessories,
      hideAccessoriesTab,
      noImages,
      noText,
      noMobile,
    } = config;
    const {
      active,
      variants = [],
      loading,
      mode,
      texts,
      images,
      showConfiguratorAlert,
      showUnsupportedMobileAlert,
      selectedImageId,
    } = this.state;
    let tabs = [
      { label: 'VARIANT', icon: 'fas fa-tshirt' },
      { label: 'COLOR', icon: 'fas fa-palette' },
      { label: 'ACCESSORIES', icon: 'far fa-gem' },
      { label: 'TEXT', icon: 'fas fa-i-cursor' },
      { label: 'IMAGES', icon: 'far fa-images' },
    ];
    if (hideAccessoriesTab || !accessories || !accessories.length) {
      tabs = tabs.filter(({ label }) => label !== 'ACCESSORIES');
    }
    if (!parts || !parts.length) {
      tabs = tabs.filter(({ label }) => label !== 'COLOR');
    }
    if (noImages) {
      tabs = tabs.filter(({ label }) => label !== 'IMAGES');
    }
    if (noText) {
      tabs = tabs.filter(({ label }) => label !== 'TEXT');
    }
    if ((IS_MOBILE && noMobile) || !variant) {
      tabs = tabs.filter(({ label }) => label === 'VARIANT');
    }
    tabs = tabs.map(({ label, icon }, index) => (
      <NavItem key={label}>
        <NavLink
          className={`${index === active ? 'active' : ''}`}
          onClick={() => this.setState({ active: index })}
          id={`tab-${label}`}
        >
          <Translate value={label} />
          <i className={`${icon}`} />
        </NavLink>
        {
          tooltips && (
            <UncontrolledTooltip placement="bottom" target={`tab-${label}`}>
              <Translate value={`TOOLTIPS.TAB_${label}`} />
            </UncontrolledTooltip>
          )
        }
      </NavItem>
    ));

    return (
      <Loadable
        active={loading}
        text={I18n.t('LOADING')}
      >
        <NavConfiguration
          loadModel={() => this.reloadConfig()}
        />
        {
          process.env.NODE_ENV === 'development' && (
            <button type="button" className="btn btn-export" onClick={() => this.exportPng()}>
              Esporta PNG
            </button>
          )
        }
        <Alert className="configurator-alert" isOpen={tooltips && showConfiguratorAlert}>
          <button
            type="button"
            className="close color-white"
            aria-label="Close"
            onClick={() => this.setState({ showConfiguratorAlert: false })}
          >
            <span aria-hidden="true">×</span>
          </button>
          <Translate value="TOOLTIPS.DRAG_ON_CONFIGURATOR" />
        </Alert>
        <Alert className="configurator-alert" isOpen={!!noMobile && showUnsupportedMobileAlert}>
          <button
            type="button"
            className="close color-white"
            aria-label="Close"
            onClick={() => this.setState({ showUnsupportedMobileAlert: false })}
          >
            <span aria-hidden="true">×</span>
          </button>
          <Translate value="UNSUPPORTED_MOBILE_ALERT" />
        </Alert>
        <Nav tabs fill className="select-bar">
          {tabs}
        </Nav>

        <BannerMode mode={mode} />

        <section className="cont-configurator">
          <div className="row configurator-background">
            <div className="col-11 col-sm-11 col-md-6 col-lg-7">
              <div id="configurator" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-5 background-initial">
              <TabContent activeTab={active}>
                <TabPane tabId={tabs.findIndex(({ key }) => key === 'VARIANT')}>
                  <Variant
                    tooltips={tooltips}
                    variants={IS_MOBILE && noMobile ? [] : variants}
                    onChangeVariant={id => this.onChangeVariant(id)}
                  />
                </TabPane>
                <TabPane tabId={tabs.findIndex(({ key }) => key === 'COLOR')}>
                  <Color
                    tooltips={tooltips}
                    parts={parts}
                    onChangeColor={(key, color) => this.onChangeColor(key, color)}
                  />
                </TabPane>
                <TabPane tabId={tabs.findIndex(({ key }) => key === 'ACCESSORIES')}>
                  <Accessory
                    tooltips={tooltips}
                    accessories={accessories}
                    hasNamedAccessories={hasNamedAccessories}
                    optionals={optionals}
                    onChangeAccessory={(key, index) => this.onChangeAccessory(key, index)}
                    onChangeOptional={(key, index) => this.onChangeOptional(key, index)}
                  />
                </TabPane>
                <TabPane tabId={tabs.findIndex(({ key }) => key === 'TEXT')}>
                  <Text
                    tooltips={tooltips}
                    texts={texts}
                    onAddText={text => this.onAddText(text)}
                    onRemoveText={id => this.onItemRemove(id)}
                    mode={mode}
                    onTextZoom={(...args) => this.onItemZoom(...args)}
                    onTextRotate={(...args) => this.onItemRotate(...args)}
                  />
                </TabPane>
                <TabPane tabId={tabs.findIndex(({ key }) => key === 'IMAGES')}>
                  <Images
                    tooltips={tooltips}
                    images={images}
                    selectedImageId={selectedImageId || ''}
                    addImage={(...args) => this.onAddImage(...args)}
                    removeImage={id => this.onItemRemove(id)}
                    onImageClick={id => this.onImageClick(id)}
                    onImagePin={(id, pinned) => this.onImagePin(id, pinned)}
                    onImageZoom={(...args) => this.onItemZoom(...args)}
                    onImageRotate={(...args) => this.onItemRotate(...args)}
                  />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </section>
      </Loadable>
    );
  }
}

Configuration.propTypes = {
  alert: PropTypes.shape({
    error: PropTypes.func,
  }) /* eslint react/require-default-props: 0 */,
  model: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired, /* eslint react/forbid-prop-types: 0 */
  dispatchSetConfig: PropTypes.func.isRequired,
  dispatchSetModel: PropTypes.func.isRequired,
  dispatchSetModelName: PropTypes.func.isRequired,
  dispatchSetVariant: PropTypes.func.isRequired,
  dispatchClear: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  tooltips: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  model: state.model,
  variant: state.variant,
  config: state.config,
  tooltips: state.tooltips,
});

const mapDispatchToProps = {
  dispatchSetModel: id => setModel(id),
  dispatchSetModelName: name => setModelName(name),
  dispatchSetVariant: id => setVariant(id),
  dispatchSetConfig: config => setConfig(config),
  dispatchClear: () => clear(),
};

export default connect(mapStateToProps, mapDispatchToProps)(withAlert(Configuration));
