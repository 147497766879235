'use strict';

/**
 * Converts a number to a html color e.g. "#aabbcc"
 * @param {number} color The input color as a number.
 * @return {string}
 */
export default function numberToColor(color) {
    let hex = Number(color).toString(16);
    hex = '000000'.substr(0, 6 - hex.length) + hex;
    return `#${hex}`;
}
