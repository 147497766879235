import { getAvailableColors, getFluoColors, color2Number } from '../helper/colors';

export const SNAPLINE_DELTA = 0.006;

const prefix = '/models';

const PARTS_COLOR_NAME = {
  1: '533 C',
  2: '300 C',
  3: '801 C',
  4: 'Giallo Fluo',
  5: 'Bright White',
};

export const getColor = (num) => {
  const colorName = PARTS_COLOR_NAME[num];
  if (!colorName) {
    return { color: 0xacacac };
  }
  const ALL_COLORS = getAvailableColors().concat(getFluoColors());
  const { hex, pantone, name: pantoneName } = ALL_COLORS.find(({ name }) => name === colorName);
  return {
    color: color2Number(hex),
    pantone,
    pantoneName,
  };
};

export const overlays = [
  `${prefix}/AXSHARED/sew.png`,
  `${prefix}/AXSHARED/ribattute.png`,
  `${prefix}/AXSHARED/bandiera.png`,
];

export const accessories = [
  {
    key: 'Elastico',
    url: [
      `${prefix}/AXSHARED/accessories/ELASTICO/ELASTICO-BIANCO.png`,
      `${prefix}/AXSHARED/accessories/ELASTICO/ELASTICO-NERO.png`,
      `${prefix}/AXSHARED/accessories/ELASTICO/ELASTICO-Custom.png`,
    ],
  },
  {
    key: 'Zip',
    url: [
      `${prefix}/AXSHARED/accessories/ZIP/ZIP-NERA.png`,
      `${prefix}/AXSHARED/accessories/ZIP/ZIP-BIANCA.png`,
    ],
  },
  // {
  //   key: 'logo_collo',
  //   url: [
  //     `${prefix}/AXSHARED/accessories/LOGO/LOGO-COLLO.png`,
  //     `${prefix}/AXSHARED/accessories/LOGO/LOGO-COLLO-BLACK.png`,
  //   ],
  //   hidden: true,
  // },
  // {
  //   key: 'logo_davanti',
  //   url: [
  //     `${prefix}/AXSHARED/accessories/LOGO/LOGO-DAVANTI.png`,
  //     `${prefix}/AXSHARED/accessories/LOGO/LOGO-DAVANTI-BLACK.png`,
  //   ],
  //   hidden: true,
  // },
  // {
  //   key: 'logo_dietro',
  //   url: [
  //     `${prefix}/AXSHARED/accessories/LOGO/LOGO-DIETRO.png`,
  //     `${prefix}/AXSHARED/accessories/LOGO/LOGO-DIETRO-BLACK.png`,
  //   ],
  //   hidden: true,
  // },
];

const getAccessoryKey = variantId => (partNumber) => {
  switch (variantId) {
    case 'base': {
      switch (partNumber) {
        case 1:
          return ['logo_davanti'];
        case 6:
          return ['logo_collo'];
        case 7:
          return ['logo_dietro'];
        default:
          return undefined;
      }
    }
    case 'first': {
      switch (partNumber) {
        case 1:
          return ['logo_collo', 'logo_dietro', 'logo_davanti'];
        default:
          return undefined;
      }
    }
    case 'sassolungo': {
      switch (partNumber) {
        case 1:
          return ['logo_collo', 'logo_davanti', 'logo_dietro'];
        default:
          return undefined;
      }
    }
    case 'third': {
      switch (partNumber) {
        case 1:
          return ['logo_collo'];
        default:
          return undefined;
      }
    }
    case 'fourth': {
      switch (partNumber) {
        case 2:
          return ['logo_collo', 'logo_dietro', 'logo_davanti'];
        default:
          return undefined;
      }
    }
    case 'fifth': {
      switch (partNumber) {
        case 1:
          return ['logo_davanti', 'logo_dietro'];
        case 3:
          return ['logo_collo'];
        default:
          return undefined;
      }
    }
    case 'sixth':
      switch (partNumber) {
        case 1:
          return ['logo_davanti', 'logo_dietro'];
        case 2:
          return ['logo_collo'];
        default:
          return undefined;
      }
    case 'seventh': {
      switch (partNumber) {
        case 2:
          return ['logo_collo'];
        default:
          return undefined;
      }
    }
    case 'eighth':
    case 'ninth': {
      switch (partNumber) {
        case 3:
          return ['logo_collo'];
        default:
          return undefined;
      }
    }
    default:
      return undefined;
  }
};

export const getBaseVariant = (image, colors = [1, 2, 3, 4, 5, 6, 7]) => ({
  id: 'base',
  name: 'Base',
  image,
  parts: colors.map(num => (
    {
      key: `COLORE-${num}`,
      url: `${prefix}/AXSHARED/parts/BASE_COLORE${num}.png`,
      ...getColor(num),
      // partAccessories: getAccessoryKey('base')(num),
    }
  )),
  overlays,
  accessories,
});

export const getFirstVariant = (image, colors = [1, 2, 3]) => ({
  id: 'cimone',
  name: 'Cimone',
  image,
  parts: colors.map(num => (
    {
      key: `COLORE-${num}`,
      url: `${prefix}/AXSHARED/parts/FIRST_COLORE${num}.png`,
      ...getColor(num),
      // partAccessories: getAccessoryKey('first')(num),
    }
  )),
  overlays,
  accessories,
});

export const getSecondVariant = (image, colors = [1, 2, 3, 4]) => ({
  id: 'sassolungo',
  name: 'Sassolungo',
  image,
  parts: colors.map(num => (
    {
      key: `COLORE-${num}`,
      url: `${prefix}/AXSHARED/parts/SASSUOLO/COLORE-${num}.png`,
      ...getColor(num),
      // partAccessories: getAccessoryKey('sassolungo')(num),
    }
  )),
  overlays,
  accessories,
});

export const getThirdVariant = (image, colors = [1, 2]) => (
  {
    id: 'dufour',
    name: 'Dufour',
    image,
    parts: colors.map(num => (
      {
        key: `COLORE-${num}`,
        url: `${prefix}/AXSHARED/parts/THIRD_COLORE${num}.png`,
        ...getColor(num),
        // partAccessories: getAccessoryKey('third')(num),
      }
    )),
    // overlays: [...overlays, `${prefix}/AXSHARED/logo_circle_black.png`],
    overlays,
    accessories: accessories.filter(({ key }) => !['logo_davanti', 'logo_dietro'].includes(key)),
  }
);

export const getFourthVariant = (image, colors = [2, 3]) => ({
  id: 'olimpo',
  name: 'Olimpo',
  image,
  parts: colors.map(num => (
    {
      key: `COLORE-${num}`,
      url: `${prefix}/AXSHARED/parts/FOURTH_COLORE${num}.png`,
      ...getColor(num),
      // partAccessories: getAccessoryKey('fourth')(num),
    }
  )),
  overlays,
  accessories,
});

export const getFifthVariant = (image, colors = [1, 3, 5]) => ({
  id: 'monviso',
  name: 'Monviso',
  image,
  parts: colors.map(num => (
    {
      key: `COLORE-${num}`,
      url: `${prefix}/AXSHARED/parts/FIFTH_COLORE${num}.png`,
      ...getColor(num),
      // partAccessories: getAccessoryKey('fifth')(num),
    }
  )),
  overlays,
  accessories,
});

export const getSixthVariant = (image, colors = [1, 2, 3]) => ({
  id: 'baldo',
  name: 'Baldo',
  image,
  parts: colors.map(num => (
    {
      key: `COLORE-${num}`,
      url: `${prefix}/AXSHARED/parts/SIXTH_COLORE${num}.png`,
      ...getColor(num),
      // partAccessories: getAccessoryKey('sixth')(num),
    }
  )),
  overlays,
  accessories,
});

export const getSeventhVariant = (image, colors = [1, 2, 3]) => (
  {
    id: 'cevedale',
    name: 'Cevedale',
    image,
    parts: colors.map(num => (
      {
        key: `COLORE-${num}`,
        url: `${prefix}/AXSHARED/parts/SEVENTH_COLORE${num}.png`,
        ...getColor(num),
        // partAccessories: getAccessoryKey('seventh')(num),
      }
    )),
    // overlays: [...overlays, `${prefix}/AXSHARED/logo_circle_black.png`],
    overlays,
    accessories: accessories.filter(({ key }) => !['logo_davanti', 'logo_dietro'].includes(key)),
  }
);

export const getEighthVariant = (image, colors = [1, 2, 3, 4]) => (
  {
    id: 'gottardo',
    name: 'Gottardo',
    image,
    parts: colors.map(num => (
      {
        key: `COLORE-${num}`,
        url: `${prefix}/AXSHARED/parts/EIGHTH_COLORE${num}.png`,
        ...getColor(num),
        // partAccessories: getAccessoryKey('eighth')(num),
      }
    )),
    // overlays: [...overlays, `${prefix}/AXSHARED/logo_black_bandiere.png`],
    overlays,
    accessories: accessories.filter(({ key }) => !['logo_davanti', 'logo_dietro'].includes(key)),
  }
);

export const getNinthVariant = (image, colors = [1, 2, 3, 4, 5]) => (
  {
    id: 'bernina',
    name: 'Bernina',
    image,
    parts: colors.map(num => (
      {
        key: `COLORE-${num}`,
        url: `${prefix}/AXSHARED/parts/NINTH_COLORE${num}.png`,
        ...getColor(num),
        // partAccessories: getAccessoryKey('ninth')(num),
      }
    )),
    // overlays: [...overlays, `${prefix}/AXSHARED/logo_circle_black.png`],
    overlays,
    accessories: accessories.filter(({ key }) => !['logo_davanti', 'logo_dietro'].includes(key)),
  }
);
