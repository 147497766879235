'use strict';

export default class ImageDrawer {
    constructor(context) {
        /** @type {CanvasRenderingContext2D} */
        this._context = context;
    }

    /**
     * @param {ImageItem} item The item to draw.
     * @return {{width: Number, height: Number}}
     */
    draw(item) {
        const {img} = item;
        const factor = 128 / img.width;
        const size = item.scale * factor;

        const width = img.width * size;
        const height = img.height * size;
        this._context.drawImage(img, -width / 2, -height / 2, width, height);

        return {width, height};
    }
}
