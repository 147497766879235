'use strict';

export const RENDER_TARGET_WIDTH = 2048;
export const RENDER_TARGET_HEIGHT = RENDER_TARGET_WIDTH * 2;
export const BUTTON_SIZE = 18;

export const MODES = {
    MOVE: 'MOVE',
    NORMAL: 'NORMAL',
    PICK_IMAGE_POSITION: 'PICK_IMAGE_POSITION',
    PICK_TEXT_POSITION: 'PICK_TEXT_POSITION',
    RESIZE: 'RESIZE',
    ROTATE: 'ROTATE',
};

export const OBJECT_TYPES = {
    IMAGE: 'IMAGE',
    TEXT: 'TEXT',
};

export const BUTTONS = {
    RESIZE: 'RESIZE',
    ROTATE: 'ROTATE',
    TRASH: 'TRASH',
};

export const getRenderTargetWidth = (quality = 'medium') => {
    switch (quality) {
        case 'high':
            return RENDER_TARGET_WIDTH;
        case 'medium':
            return RENDER_TARGET_WIDTH / 2;
        case 'low':
            return RENDER_TARGET_WIDTH / 4;
        case 'mobile':
            return RENDER_TARGET_WIDTH / 8;
        default:
            return RENDER_TARGET_WIDTH;
    }
};

export const getRenderTargetHeight = (quality = 'medium') => {
    switch (quality) {
        case 'high':
            return RENDER_TARGET_HEIGHT;
        case 'medium':
            return RENDER_TARGET_HEIGHT / 2;
        case 'low':
            return RENDER_TARGET_HEIGHT / 4;
        case 'mobile':
            return RENDER_TARGET_HEIGHT / 8;
        default:
            return RENDER_TARGET_HEIGHT;
    }
};

export const EDITABLE_TEXT_PROPS = ['bold', 'color', 'font', 'italic', 'text'];

export const DEFAULT_DIRECTIONAL_LIGHTS = [
    { intensity: 0.5 },
    { intensity: 0.6 },
];
