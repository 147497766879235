const TERMS_URL_IT = 'https://alka-sport.com/privacy-policy/';
const TERMS_URL_EN = 'https://alka-sport.com/privacy-policy/';
const THANK_YOU_RETURN_URL_IT = 'https://alka-sport.com/customlab/';
const THANK_YOU_RETURN_URL_EN = 'https://alka-sport.com/customlab/';

export default {
  en: {
    NOT_FOUND_MESSAGE: 'Page not found.',
    SELECT_A_MODEL: 'Select an article',
    MODEL_SELECTED: 'Selected article',
    CONTINUE: 'NEXT',
    SELECT: 'SELECT',
    VARIANT: 'Graphics',
    COLOR: 'Color',
    TEXT: 'Text',
    IMAGES: 'Images',
    SELECT_A_VARIANT: 'Select graphics',
    LOADING: 'Loading...',
    SELECT_IMAGE: 'Upload an image',
    ERROR: 'Error. Please try later',
    INSERT_TEXT: 'Insert text',
    ADD: 'Add',
    CHOOSE_FILE: 'Upload',
    FIELD_REQUIRED: 'Required',
    BACK: 'Back',
    FORM_TITLE: 'Fill the form',
    FORM_SUBTITLE: 'By submitting this form you will send a quotation request for the customization of the selected product.<br>Fields with an "*" are required.<br>We\'ll get in touch with you as soon as possible.',
    SUBMIT_FORM: 'Send request',
    PICK_TEXT_POSITION: 'Click on the article to position text or images',
    PICK_IMAGE_POSITION: 'Click on the article to position text or images',
    EDIT: 'Edit',
    INSERTED_TEXTS: 'Inserted texts',
    FILE_TOO_LARGE: 'File is too big. You can upload pictures up to 2 MB',
    TOO_MANY_FILES: 'You have reached the maximum image size you can upload.<br>Delete or replace some of them with smaller ones.',
    PLACEHOLDERS: {
      NAME_AND_SURNAME: 'Name and surname*',
      EMAIL: 'Email*',
      PHONE: 'Telephone*',
      TEAM_NAME: 'Team Name*',
      TEAM_MEMBERS: 'No. of team members*',
      PROVINCE: 'District*',
      PROVINCE_OPTIONAL: 'District',
      STATE: 'State*',
      NOTES: 'Notes',
    },
    VALIDATION: {
      EMAIL: 'Insert an email address',
      REQUIRED: 'Required',
      TERMS: 'You must accept terms and conditions to send a quotation request.',
    },
    SUBMIT: {
      ERROR: 'It was not possibile to send the request. Please try later.',
      SUCCESS: 'We\'ll get in touch with you as soon as possible.',
      VALIDATION: 'It seems the form is not filled correctly.',
    },
    CHECKOUT: 'REQUEST QUOTATION',
    SELECT_ACCESSORY: 'Select an accessory',
    ACCESSORIES: 'Accessories',
    OPTION: 'Options',
    ERROR_IMAGE_MIN_DIMENSION: 'Image must have a minimum resolution of %{dimension}x%{dimension} pixels',
    PANTONE_NOT_FOUND: 'Color code not available',
    INSERT_PANTONE: 'Search for a Pantone',
    ARE_YOU_SURE: 'Are you sure?',
    BACK_TO_MODELS_MSG: 'By going back, your customization will be lost.',
    YES: 'Yes',
    NO: 'No',
    MODEL_CODE: 'article code:',
    BOLD: 'Bold',
    ITALICS: 'Italics',
    ALL_COLORS: 'All',
    LESS_COLORS: 'Less',
    FLUO: 'Fluo',
    TERMS_LABEL: `I accept <a href="${TERMS_URL_EN}" target="_blank">terms and conditions</a>`,
    STATISTICS_LABEL: 'I agree that my data may be used for statistical purposes',
    MARKETING_LABEL: 'I agree that my data may be used for marketing purposes',
    THANK_YOU: 'Your request has been sent successfully',
    THANK_YOU_RETURN_MESSAGE: 'Back to CustomLab',
    THANK_YOU_RETURN_URL: THANK_YOU_RETURN_URL_EN,
    TAKE_OUR_SURVEY: 'TAKE OUR SURVEY',
    UNSUPPORTED_MOBILE_ALERT: 'ALERT: The configurator is currently available only on PCs and Macs.',
    TOOLTIPS: {
      SELECT_MODEL_1: 'Click to select an article ',
      SELECT_MODEL_2: ' and proceed to customization',
      TAB_VARIANT: 'Select a base graphics for you article. Il will be you starting point for your customization.',
      TAB_COLOR: 'Select the colors that will be applied to the article\'s parts.',
      TAB_ACCESSORIES: 'Select the available accessories',
      TAB_TEXT: 'Add custom texts.',
      TAB_IMAGES: 'Add images and logos.',
      SELECT_VARIANT: 'Click to apply this graphics',
      COLOR_ITEM: 'Click to show or hide the list of the available colors.',
      SELECT_PANTONE: 'Click to apply the color ',
      SEARCH_PANTONE: 'Search for a Pantone® by exact name or code.',
      START_SEARCH: 'Search.',
      SELECT_ACCESSORY: 'Click to select an option ',
      SELECT_TEXT_COLOR: 'Click to select a color for your text.',
      ENTER_TEXT_HERE: 'Insert here the text you want to add.',
      SELECT_FONT: 'Select a font for your text',
      APPLY_TEXT: 'Click here to apply a text to the graphics.',
      REMOVE_TEXT: 'Click here to remove text.',
      CONFIRM_EDIT: 'Click here to confirm your editing.',
      UPLOAD_IMAGE: 'Click here to upload an image from your device',
      DELETE_IMAGE: 'Click here to remove this image.',
      CLONE_IMAGE: 'Click here to duplicate this image.',
      SELECT_IMAGE: 'Click on the image to highlight it in the configurator',
      LOCK_IMAGE: 'Lock or unlock the image',
      ZOOM_IN_IMAGE: 'Zoom in',
      ZOOM_OUT_IMAGE: 'Zoom out',
      ROTATE_CLOCKWISE_IMAGE: 'Rotate clockwise',
      ROTATE_COUNTERCLOCKWISE_IMAGE: 'Rotate counterclockwise',
      DRAG_ON_CONFIGURATOR: 'SUGGESTION: Drag the article with your mouse or finger to rotate it. Scroll or pinch to zoom in or out.',
      SEND_REQUEST: 'Click here to send a quotation request. We\'ll get in touch with you as soon as possible.',
      HIDE: 'Hide suggestions',
      SHOW: 'Show suggestions',
      CHECKOUT: 'Go to the next page to send a quotation request. You\' be able to cancel and go back.',
      ALL_COLORS: 'Click to see all the available colors',
      MOST_USED_COLORS: 'Click to see the most used colors',
      FLUO_COLORS: 'Click to see fluo colors',
    },
    MODELS: {
      AX512: {
        DESCRIPTION: 'Smeraldo maglia estiva manica corta taglio al vivo',
      },
      AX512RM: {
        DESCRIPTION: 'Smeraldo maglia estiva manica corta con riporto manica',
      },
      AX512TF: {
        DESCRIPTION: 'Smeraldo maglia estiva manica corta comfort',
      },
      AX513: {
        DESCRIPTION: 'Rubino maglia estiva manica corta',
      },
      AX514: {
        DESCRIPTION: 'Maglia estiva manica corta',
      },
    },
    TERMS_URL: 'https://alka-sport.com/privacy-policy/',
    BIANCA: 'White',
    BIANCHI: 'White',
    BIANCO: 'White',
    NERA: 'Black',
    NERI: 'Black',
    NERO: 'Black',
    'AZZURRO ITALIA': 'Italy Azure',
    'BLU MARINE': 'Blue Marine',
    ROSSA: 'Red',
    ROSSO: 'Red',
    ROYAL: 'Royal',
    ALKA: 'Alka',
  },
  it: {
    NOT_FOUND_MESSAGE: 'Non abbiamo trovato la pagina che stai cercando.',
    SELECT_A_MODEL: 'Scegli un articolo',
    MODEL_SELECTED: 'Articolo scelto',
    CONTINUE: 'PROSEGUI',
    SELECT: 'SELEZIONA',
    VARIANT: 'Grafica',
    COLOR: 'Colore',
    TEXT: 'Testo',
    IMAGES: 'Immagini',
    SELECT_A_VARIANT: 'Seleziona una grafica',
    LOADING: 'Caricamento...',
    SELECT_IMAGE: 'Carica un\'immagine',
    ERROR: 'Errore. Riprova più tardi',
    INSERT_TEXT: 'Inserisci testo',
    ADD: 'Aggiungi',
    CHOOSE_FILE: 'Carica',
    FIELD_REQUIRED: 'Campo obbligatorio',
    BACK: 'Indietro',
    FORM_TITLE: 'Compila il form',
    FORM_SUBTITLE: 'Compilando il form inoltrerai la richiesta di preventivo per la personalizzazione del prodotto selezionato.<br>I campi con l\'asterisco sono obbligatori.<br>Verrai ricontattato nel più breve tempo possibile.',
    SUBMIT_FORM: 'Invia richiesta',
    PICK_TEXT_POSITION: 'Clicca sull\'articolo per posizionare il testo o l\'immagine',
    PICK_IMAGE_POSITION: 'Clicca sull\'articolo per posizionare il testo o l\'immagine',
    EDIT: 'Modifica',
    INSERTED_TEXTS: 'Testi inseriti',
    FILE_TOO_LARGE: 'La dimensione del file è eccessiva. Puoi caricare immagini fino ad un massimo di 2 MB',
    TOO_MANY_FILES: 'Hai raggiunto la dimensione massima delle immagini che puoi caricare.<br>Rimuovine alcune oppure sostituiscile con file dal peso inferiore.',
    PLACEHOLDERS: {
      NAME_AND_SURNAME: 'Nome e Cognome*',
      EMAIL: 'Email*',
      PHONE: 'Telefono*',
      TEAM_NAME: 'Nome Squadra*',
      TEAM_MEMBERS: 'N. di componenti*',
      PROVINCE: 'Provincia*',
      PROVINCE_OPTIONAL: 'Provincia',
      STATE: 'Stato*',
      NOTES: 'Note',
    },
    VALIDATION: {
      EMAIL: 'Inserire un indirizzo email',
      REQUIRED: 'Campo obbligatorio',
      TERMS: 'Devi accettare termini e condizioni per poter inviare la richiesta',
    },
    SUBMIT: {
      ERROR: 'Non è stato possibile inviare la richiesta, riprova più tardi.',
      SUCCESS: 'Sarai ricontattato al più presto.',
      VALIDATION: 'Sembra che il form non sia compilato correttamente.',
    },
    CHECKOUT: 'RICHIEDI PREVENTIVO',
    SELECT_ACCESSORY: 'Seleziona un accessorio',
    ACCESSORIES: 'Accessori',
    OPTION: 'Opzione',
    ERROR_IMAGE_MIN_DIMENSION: 'L\'immagine deve avere dimensione minima di %{dimension}x%{dimension}',
    PANTONE_NOT_FOUND: 'Codice colore non disponibile',
    INSERT_PANTONE: 'Cerca un pantone',
    ARE_YOU_SURE: 'Sei sicuro?',
    BACK_TO_MODELS_MSG: 'Tornando indietro, le eventuali personalizzazioni apportate saranno cancellate.',
    YES: 'Sì',
    NO: 'No',
    MODEL_CODE: 'codice articolo:',
    BOLD: 'Grassetto',
    ITALICS: 'Corsivo',
    ALL_COLORS: 'Tutti',
    LESS_COLORS: 'Meno',
    FLUO: 'Fluo',
    TERMS_LABEL: `Accetto <a href="${TERMS_URL_IT}" target="_blank">termini e condizioni</a> di utilizzo`,
    STATISTICS_LABEL: 'Acconsento all\'utilizzo dei dati per fini statistici',
    MARKETING_LABEL: 'Acconsento all\'utilizzo dei dati per finalità di marketing',
    THANK_YOU: 'La tua richiesta è stata inviata con successo!',
    THANK_YOU_RETURN_MESSAGE: 'Torna al CustomLab',
    THANK_YOU_RETURN_URL: THANK_YOU_RETURN_URL_IT,
    TAKE_OUR_SURVEY: 'PARTECIPA AL NOSTRO SONDAGGIO',
    UNSUPPORTED_MOBILE_ALERT: 'ATTENZIONE: Il configuratore è temporaneamente disponibile solo su PC e Mac.',
    TOOLTIPS: {
      SELECT_MODEL_1: 'Clicca per scegliere l\'articolo ',
      SELECT_MODEL_2: ' e procedere alla personalizzazione',
      TAB_VARIANT: 'Seleziona una grafica base da applicare all\'articolo. Sarà il punto di partenza per la tua personalizzazione.',
      TAB_COLOR: 'Seleziona i colori da applicare alle varie parti dell\'articolo.',
      TAB_ACCESSORIES: 'Seleziona gli accessori disponibili per l\'articolo.',
      TAB_TEXT: 'Aggiungi testi personalizzati al tuo articolo.',
      TAB_IMAGES: 'Aggiungi immagini e loghi al tuo articolo.',
      SELECT_VARIANT: 'Clicca per applicare questa grafica al tuo modello',
      COLOR_ITEM: 'Clicca per espandere o nascondere la lista dei colori disponibili per questa parte dell\'articolo.',
      SELECT_PANTONE: 'Clicca per applicare il colore ',
      SEARCH_PANTONE: 'Cerca un colore Pantone® specificandone il nome esatto o il codice.',
      START_SEARCH: 'Avvia la ricerca.',
      SELECT_ACCESSORY: 'Clicca per scegliere un\'opzione per l\'accessorio ',
      SELECT_TEXT_COLOR: 'Clicca per scegliere un colore per il testo.',
      ENTER_TEXT_HERE: 'Inserisci qui il testo che vuoi aggiungere.',
      SELECT_FONT: 'Seleziona un carattere per il testo',
      APPLY_TEXT: 'Clicca qui per applicare il testo alla grafica.',
      REMOVE_TEXT: 'Clicca qui per eliminare questo testo dalla grafica.',
      CONFIRM_EDIT: 'Clicca qui per confermare le modifiche.',
      UPLOAD_IMAGE: 'Clicca qui per caricare un\'immagine dal tuo dispositivo',
      DELETE_IMAGE: 'Clicca qui per eliminare questa immagine dalla grafica.',
      CLONE_IMAGE: 'Clicca qui per duplicare questa immagine sulla grafica.',
      SELECT_IMAGE: 'Clicca sull\'immagine per evidenziarla nel configuratore',
      LOCK_IMAGE: 'Blocca o sblocca l\'immagine',
      ZOOM_IN_IMAGE: 'Aumenta lo zoom dell\'immagine',
      ZOOM_OUT_IMAGE: 'Diminuisci lo zoom dell\'immagine',
      ROTATE_CLOCKWISE_IMAGE: 'Ruota in senso orario l\'immagine',
      ROTATE_COUNTERCLOCKWISE_IMAGE: 'Ruota in senso antiorario l\'immagine',
      DRAG_ON_CONFIGURATOR: 'SUGGERIMENTO: Trascina l\'articolo con il mouse o con il tocco per ruotarlo. Usa la rotellina del mouse o il tocco con due dita per lo zoom.',
      SEND_REQUEST: 'Clicca qui per inviare una richiesta di preventivo. Sarai ricontattato al più presto.',
      HIDE: 'Nascondi suggerimenti',
      SHOW: 'Mostra suggerimenti',
      CHECKOUT: 'Vai alla pagina successiva per richiedere un preventivo. Potrai annullare o tornare indietro.',
      ALL_COLORS: 'Clicca per vedere tutti i colori disponibili',
      MOST_USED_COLORS: 'Clicca per vedere i colori più usati',
      FLUO_COLORS: 'Clicca per vedere i colori fluo',
    },
    MODELS: {
      AX512: {
        DESCRIPTION: 'Smeraldo maglia estiva manica corta taglio al vivo',
      },
      AX512RM: {
        DESCRIPTION: 'Smeraldo maglia estiva manica corta con riporto manica',
      },
      AX512TF: {
        DESCRIPTION: 'Smeraldo maglia estiva manica corta comfort',
      },
      AX513: {
        DESCRIPTION: 'Rubino maglia estiva manica corta',
      },
      AX514: {
        DESCRIPTION: 'Maglia estiva manica corta',
      },
    },
    TERMS_URL: 'https://alka-sport.com/privacy-policy/',
    BIANCA: 'Bianca',
    BIANCHI: 'Bianchi',
    BIANCO: 'Bianco',
    NERA: 'Nera',
    NERI: 'Neri',
    NERO: 'Nero',
    'AZZURRO ITALIA': 'Azzurro Italia',
    'BLU MARINE': 'Blue Marine',
    ROSSA: 'Rossa',
    ROSSO: 'Rosso',
    ROYAL: 'Royal',
    ALKA: 'Alka',
  },
};
