import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { I18n } from 'react-i18nify';
import { version } from '../../../package.json';

const { REACT_APP_ENABLE_RETURN_URL } = process.env;
const enableReturnUrl = REACT_APP_ENABLE_RETURN_URL === 'true';

const NavHome = () => (
  <Navbar color="light" light expand="md">
    <NavbarBrand href={enableReturnUrl ? I18n.t('THANK_YOU_RETURN_URL') : '/'}>
      <img src="/images/palmabit-payoff.png" alt="Palmabit" className="img-fluid" />
    </NavbarBrand>
    {/*<span className="float-right">*/}
    {/*  {`v. ${version}`}*/}
    {/*</span>*/}
  </Navbar>
);

export default NavHome;
