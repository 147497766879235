'use strict';

import numberToColor from '../numberToColor';

export default class TextDrawer {
    constructor(context) {
        /** @type {CanvasRenderingContext2D} */
        this._context = context;
    }

    /**
     * @param {TextItem} item The item to draw.
     * @return {{width: Number, height: Number}}
     */
    draw(item) {
        const {bold, color, font, italic, text} = item;
        const size = item.size * item.scale;
        this._context.fillStyle = numberToColor(color);
        this._context.font = `${bold ? 'bold ' : ''}${italic ? 'italic ' : ''}${size}px ${font}`;
        this._context.textBaseline = 'bottom';

        const {width} = this._context.measureText(text);
        this._context.fillText(text, -width / 2, size / 2);

        return {width, height: size};
    }
}
