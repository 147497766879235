'use strict';

import _ from 'lodash';
import nanoid from 'nanoid';

export default class Item {
    constructor(type, point, uv) {
        Object.defineProperty(this, 'type', {value: type, enumerable: true});
        Object.defineProperty(this, 'id', {value: nanoid(), enumerable: true});
        this.angle = 0;
        this.point = point;
        this.scale = 1;
        this.uv = uv;
        this.pinned = false;
        this.effectiveSize = {};
    }

    /**
     * @param {THREE.Vector3} point Center point.
     * @param {THREE.Vector2} uv Center point in uv (texture) coordinates.
     */
    moveTo(point, uv) {
        this.point = point;
        this.uv = uv;
    }

    toJson() {
        return _.pick(this, ['angle', 'effectiveSize', 'id', 'pantone', 'pantoneName', 'pinned', 'scale', 'type']);
    }

    export() {
        return Object.assign(this.toJson(), {point: this.point.toArray(), uv: this.uv.toArray(), id: undefined});
    }

    update(opts) {
        Object.assign(this, _.pick(opts, ['angle', 'pantone', 'pantoneName', 'pinned', 'scale']));
    }

    setEffectiveSize(width, height) {
        this.effectiveSize = {height, width};
    }
}
