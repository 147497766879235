'use strict';

/**
 * Normalizes a value between a range, by default an angle between [0, 2PI)
 * @see {@link https://stackoverflow.com/a/2021986/2564990}
 * @param {Number} value The input value to normalize.
 * @param {Number} start The beginning of the range (default 0)
 * @param {Number} end The end of the range (default 2PI)
 * @return {Number}
 */
const normalizeAngle = (value, start = 0, end = 2 * Math.PI) => {
    const width = end - start;
    const offsetValue = value - start;

    return (offsetValue - (Math.floor(offsetValue / width) * width)) + start;
};

export default normalizeAngle;
